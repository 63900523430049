/* eslint-disable react/jsx-indent */
import React, { useState, useContext, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/layout/Layout';
import UserHeading from '../../components/global/UserHeading';
import ImageFromField from '../../components/global/ImageFromField';
import CompLink from '../../components/global/Link';
import { UserContext } from '../../context/user/context';
import { FollowBtn } from '../../components/global/ActionButtons';
import useAuthGate from '../../util/hooks/useAuthGate';
import useGetArtists from '../../util/hooks/useGetArtists';
import SEO from '../../components/SEO';
import axios from 'axios';
import { baseUrl } from '../../util/auth/drupal';

const ArtistCard = ({ artist, device, showFollowing = false }) => (
  <div className="grid-col">
    <div className="card">
      <div className="card-image">
        <Link to={artist.path.alias}>
          <figure className="image is-3by3">
            <ImageFromField
              img={artist.relationships.field_artist_listing_image}
              device={device}
              format={'json'}
              imageSize="gatsby_artist_pic"
              fallback={'./media/logo.png'}
            />
          </figure>
        </Link>
      </div>
      <div className="card-content">
        <Link to={artist.path.alias}>
          <h3 className="title is-4">
            {artist.field_lastname}, {artist.field_firstname}
          </h3>
        </Link>
        {showFollowing ? (
          <FollowBtn drupal_id={artist.id} followingLabel={`Unfollow`} />
        ) : null}
      </div>
    </div>
  </div>
);

const FavouriteArtistsPage = ({ data }) => {
  const [device, setDevice] = useState('mobile');
  const { state } = useContext(UserContext);
  const { user } = state;
  useAuthGate('/user/login');
  const {
    setArtistIds,
    artists: favouriteArtists,
    loadingArtists: loadingFavArtists,
    message,
  } = useGetArtists(user.field_artist_of_interest);

  const {
    setArtistIds: setArtistsIdsToFollow,
    artists: artistsToFollow,
    loadingArtists: loadingFollowArtists,
    message: followMessage,
  } = useGetArtists([]);

  useEffect(() => {
    setArtistIds(user.field_artist_of_interest);
  }, [user.field_artist_of_interest]);

  useEffect(() => {
    const getIds = async () => {
      const ids = await axios
        .get(`${baseUrl}/jsonapi/recommended_artists`)
        .then((res) => res.data)
        .catch((error) => console.error(error));

      if (ids && ids.status !== "nothing found") {
        setArtistsIdsToFollow(() => [...ids.slice(0, 8)]);
      }
    };

    getIds();
  }, []);

  /* console.log('favorite artists', favouriteArtists); */

  return (
    <Layout
      device={device}
      setDevice={setDevice}
      logo={data.logo}
      footerMenu={data.footer_menu}
      menu={data.allMenuMenuLink}
      locations={data.locations.edges}
    >
      <SEO title="Favourite Artists" />
      <UserHeading favouriteArtists={true} username={user.title} />
      <main className="user-page favourite-artists">
        <section className="grid-section grid-content-container">
          <h2 className="t-xlarge t-heading t-uppercase sub-section-title">
            Favourite Artists
          </h2>
          <div className="spacer"></div>
          {loadingFavArtists ? (
            <div className="grid-4 artists grid-2-md grid-1-sm">
              {new Array(4).fill(0).map((_, i) => (
                <div key={i} className="artwork-skeleton grid-col"></div>
              ))}
            </div>
          ) : favouriteArtists.length > 0 ? (
            <div className="grid-4 artists grid-2-md grid-1-sm">
              {favouriteArtists.map(({ data: artist }, i) => (
                <ArtistCard
                  artist={artist}
                  device={device}
                  key={artist.id}
                  showFollowing={true}
                  className="grid-col"
                  json={true}
                />
              ))}
            </div>
          ) : (
            <>
              <h4>{message}</h4>
              <div className="spacer"></div>
              <CompLink
                button
                className={`btn-large btn-center btn-space-large`}
                path={`/artists/`}
                linkkey={`no_favourites_btn`}
                device={device}
              >
                Browse artists
              </CompLink>
            </>
          )}

          <div className="spacer"></div>
        </section>

        {loadingFavArtists ? (
          <section className="grid-section user-title grid-content-container">
            <h2 className="t-xlarge t-heading t-uppercase sub-section-title">
              Artists To Follow
            </h2>
            <div className="grid-4 artists grid-2-md grid-1-sm">
              {new Array(4).fill(0).map((_, i) => (
                <div key={i} className="artwork-skeleton grid-col"></div>
              ))}
            </div>
          </section>
        ) : artistsToFollow.length > 0 ? (
          <section className="grid-section user-title grid-content-container">
            <h2 className="t-xlarge t-heading t-uppercase sub-section-title">
              Artists To Follow
            </h2>
            <div className="grid-4 artists grid-2-md grid-1-sm">
              {artistsToFollow.map(({ data: artist }) => (
                <ArtistCard artist={artist} device={device} key={artist.id} />
              ))}
            </div>
          </section>
        ) : null
        // <>
        //   <h4>{followMessage}</h4>
        //   <div className="spacer"></div>
        //   <div className="t-right">
        //     <CompLink
        //       simplebutton
        //       className={`btn-large btn-center`}
        //       path={`/artists/`}
        //       linkkey={`browse_all_btn`}
        //       device={device}
        //     >
        //       Browse all artists
        //     </CompLink>
        //   </div>
        // </>
        }
      </main>
    </Layout>
  );
};

export default FavouriteArtistsPage;

export const UserFavouriteArtistsPageQuery = graphql`
  query UserFavouriteArtistsPageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
  }
`;
