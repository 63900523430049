import { useState, useEffect } from 'react';
import { merge } from 'lodash';
import { baseUrl, forceHTTPS } from '../auth/drupal';

/**
 * Hook for fetching artists by their ids. Will also fetch their images.
 * Will filter out failed fetches.
 * @param {[strings]} artists_ids
 * @returns {[{artists}, boolean, string]}
 */
const useGetArtists = (artists_ids = []) => {
  const [artistIds, setArtistIds] = useState(artists_ids);
  const [artists, setArtists] = useState([]);
  const [loadingArtists, setLoadingArtists] = useState(true);
  const [message, setMessage] = useState(`Loading...`);

  useEffect(() => {
    if (artistIds && artistIds.length > 0) {
      const getArtists = async () => {
        try {
          setLoadingArtists(true);
          // map over every item and only render when they all resolve
          const items = await Promise.all(
            artistIds.map(async (id) => {
              const res = await fetch(
                `${baseUrl}/jsonapi/node/artist/${id}`
              );

              //console.log('data response', res);

              if (res.ok) {
                const data = await res.json();
                const dataDeepCopy = JSON.parse(JSON.stringify(data));

                merge(dataDeepCopy.data, dataDeepCopy.data.attributes);
                delete dataDeepCopy.data.attributes;

                // check if there is an image and then mutate the data object
                if (data.data.relationships) {
                  const fetchImage = await fetch(
                    forceHTTPS(data?.data?.relationships?.field_artist_listing_image?.links?.related?.href)
                  );

                  if (fetchImage.ok) {

                    let imageRes = {data: {
                      url: null,
                      image_style_uri: null
                    }}

                    try {
                      imageRes = await fetchImage.json();

                      if (imageRes.data) {
                        dataDeepCopy.data.relationships.field_artist_listing_image.uri = {
                          url: imageRes.data.attributes.uri.url,
                          image_style_uri: imageRes.data.attributes.image_style_uri
                        };
                      }
                    } catch(e) {
                      console.log('FAILED TO OBTAIN ARTIST IMAGE', e);
                    }

                  }
                }
                return dataDeepCopy;
              }
            })
          );

          // we may get some undefined values if the artist gives a 401
          const filteredItems = items.filter(Boolean);

          setArtists(() => [...filteredItems]);
          setLoadingArtists(false);
        } catch (error) {
          console.log(error);
          setMessage(
            `There was an error loading the artists. Please reload the page. 
            If this issue persists then please get in contact with the owner of this website.`
          );
          setLoadingArtists(false);
        }
      };

      getArtists();
    } else {
      setMessage(`There currently are no artists.`);
      setLoadingArtists(false);
    }
  }, [artistIds, setArtistIds]);

  return { setArtistIds, artists, loadingArtists, message };
};

export default useGetArtists;
